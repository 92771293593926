<template>
  <div>
    <b-breadcrumb style="margin-bottom : 20px">
      <b-breadcrumb-item to="/dashboard">
        <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{ path: `/dashboard/docreated/${itemModel}/${dealerCode}` }"
      >
        DO Created
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        Detail Delivery Order Created
      </b-breadcrumb-item>
    </b-breadcrumb>
    <b-card no-body>
      <b-card-header><h4>Detail DO Created</h4></b-card-header>
      <b-card-body>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-button size="md" variant="primary" @click="$router.go(-1)"
                ><feather-icon size="16" icon="HomeIcon" /> Main Menu</b-button
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Item Model</label>
                <p class="font-weight-bold">{{ itemData.itemModel }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Item Name</label>
                <p class="font-weight-bold">{{ itemData.description }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ dataDetail.sapDo }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP SO Number</label>
                <p class="font-weight-bold">{{ dataDetail.sapSo }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>PO Number</label>
                <p class="font-weight-bold">{{ dataDetail.customerPo }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Dealer Number</label>
                <p class="font-weight-bold">{{ dataDetail.dealerNo }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>DO Created</label>
                <p class="font-weight-bold">
                  {{ dataDetail.createdAt | formatDate }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Date</label>
                <p class="font-weight-bold">
                  {{ dataDetail.deliveryDate | formatDate }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <!-- <b-col cols="12" class="mt-2">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Status</label>
                <p class="font-weight-bold">{{ dataDetail.status }}</p>
              </div>
            </b-col>
          </b-row>
        </b-col> -->

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer Information</label>
                <p class="font-weight-bold" v-if="dataDetail.CustomerName1">
                  {{ dataDetail.CustomerName1 }}
                </p>
                <p class="font-weight-bold" v-if="dataDetail.CustomerName2">
                  {{ dataDetail.CustomerName2 }}
                </p>
                <p class="font-weight-bold" v-if="dataDetail.CustomerName3">
                  {{ dataDetail.CustomerName3 }}
                </p>
                <p class="font-weight-bold" v-if="dataDetail.CustomerName4">
                  {{ dataDetail.CustomerName4 }}
                </p>
                <p class="font-weight-bold" v-if="dataDetail.phone">
                  {{ dataDetail.phone }}
                </p>
                <p class="font-weight-bold" v-if="dataDetail.email">
                  {{ dataDetail.email }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Address</label>
                <p class="font-weight-bold">
                  {{ dataDetail.deliveryAddress1 }}
                  {{ dataDetail.deliveryAddress2 }}
                  {{ dataDetail.deliveryAddress3 }}
                </p>
                <p class="font-weight-bold">{{ dataDetail.deliveryCity }}</p>
                <p class="font-weight-bold">
                  {{ dataDetail.deliveryPostalCode }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      itemModel: "",
      dealerCode: "",
      id: "",
      dataDetail: {},
      itemData: {},
    };
  },
  methods: {
    ...mapActions({
      detailProcessing: "dashboard/detailDOCreated",
    }),
  },
  mounted() {
    this.dealerCode = this.$route.params.dealerCode;
    this.itemModel = this.$route.params.itemModel;
    this.id = this.$route.params.id;

    this.detailProcessing({
      itemModel: this.itemModel,
      id: this.id,
      dealerCode: this.dealerCode,
    })
      .then((response) => {
        // console.log("BERHASIL GET DETAIL", response);
        this.dataDetail = response.data.data;
        this.itemData = response.data.itemData;
      })
      .catch((err) => {
        // console.log("INI ERROR DETAIL");
        console.log({ err });
      });
  },
};
</script>
